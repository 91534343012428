import React, { useEffect, useState } from "react";
import { useProfile } from "@ludens-reklame/rubics-v3-react/profile/hooks";
import { Profile } from "@ludens-reklame/rubics-v3-sdk/profile/types";
import { arrow } from "../../icons/svg.js";
import { Busy } from "../../internal-components/busy/busy.js";
import { RichText } from "../../internal-components/richText/RichText.js";
import { ProfileProps } from "../../types/profile.js";

interface Props {
  title?: string;
  text?: string;
}

const MyProfile: React.FC<Props> = ({ title, text }) => {
  const { profile, update, networking } = useProfile<ProfileProps>();
  const [isEditMode, setIsEditMode] = useState(false);
  const [formValues, setFormValues] = useState<Partial<Profile<ProfileProps>>>(
    profile || {}
  );

  useEffect(() => {
    setFormValues(profile || {});
  }, [profile]);

  const handleUpdate = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (isEditMode && !networking) {
      await update(formValues);
      setIsEditMode(false);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;

    if (id === "billingEmail") {
      setFormValues({ ...formValues, billing: { email: value } });
      return;
    }

    setFormValues({ ...formValues, [id]: value });
  };

  return (
    <Busy busy={networking}>
      <div className="profile-wrapper mw hs vs-xl">
        <div className="profile-container">
          <div className="title-container">
            {title && (
              <h1 className="h4">
                {title} {formValues?.fullName}
              </h1>
            )}
            {text && <RichText className="b1" html={text} />}
          </div>
          <div>
            <h2 className="h6">Dine opplysninger</h2>
            <form className="profile-form" onSubmit={handleUpdate}>
              <div className="item-container">
                <label className="b3" htmlFor="firstName">
                  Fornavn
                </label>
                {isEditMode ? (
                  <input
                    className="b1"
                    type="text"
                    id="firstName"
                    value={formValues.firstName}
                    onChange={handleChange}
                  />
                ) : (
                  <p>{formValues.firstName ?? "Mangler info"}</p>
                )}
              </div>
              <div className="item-container">
                <label className="b3" htmlFor="lastName">
                  Etternavn
                </label>
                {isEditMode ? (
                  <input
                    className="b1"
                    type="text"
                    id="lastName"
                    value={formValues.lastName}
                    onChange={handleChange}
                  />
                ) : (
                  <p>{formValues.lastName ?? "Mangler info"}</p>
                )}
              </div>
              <div className="item-container">
                <label className="b3" htmlFor="email">
                  Epost
                </label>
                {isEditMode ? (
                  <input
                    className="b1"
                    type="email"
                    id="email"
                    value={formValues.email}
                    onChange={handleChange}
                  />
                ) : (
                  <p>{formValues.email ?? "Mangler info"}</p>
                )}
              </div>
              <div className="item-container">
                <label className="b3" htmlFor="billingEmail">
                  Faktura epost
                </label>
                {isEditMode ? (
                  <input
                    className="b1"
                    type="email"
                    id="billingEmail"
                    value={formValues.billing?.email}
                    onChange={handleChange}
                  />
                ) : (
                  <p>{formValues.billing?.email ?? "Mangler info"}</p>
                )}
              </div>
              <div className="item-container">
                <label className="b3" htmlFor="company">
                  Bedrift
                </label>
                {isEditMode ? (
                  <input
                    className="b1"
                    type="text"
                    id="company"
                    value={formValues.company}
                    onChange={handleChange}
                  />
                ) : (
                  <p>{formValues.company ?? "Mangler info"}</p>
                )}
              </div>
              <div className="item-container">
                <label className="b3" htmlFor="vat">
                  Organisasjonsnummer
                </label>
                {isEditMode ? (
                  <input
                    className="b1"
                    type="text"
                    id="vat"
                    value={formValues.vat}
                    onChange={handleChange}
                  />
                ) : (
                  <p>{formValues.vat ?? "Mangler info"}</p>
                )}
              </div>
              <div className="item-container">
                <label className="b3" htmlFor="profession">
                  Yrkesgruppe
                </label>
                {isEditMode ? (
                  <select
                    id="profession"
                    name="profession"
                    className="b1"
                    value={formValues.props?.profession}
                    onChange={(e) => {
                      setFormValues({
                        ...formValues,
                        props: { profession: e.target.value },
                      });
                    }}
                  >
                    <option value="">Vennligst Velg</option>
                    <option value="Lysdesigner/Belysningsplanlegger">
                      Lysdesigner/Belysningsplanlegger
                    </option>
                    <option value="Arkitekt">Arkitekt</option>
                    <option value="Salg og markedsføring">
                      Salg og markedsføring
                    </option>
                    <option value="Elektriker/installatør">
                      Elektriker/installatør
                    </option>
                    <option value="RIE: rådgivende ingeniør elektro">
                      RIE: rådgivende ingeniør elektro
                    </option>
                    <option value="Annet">Annet</option>
                  </select>
                ) : (
                  <p>{formValues.props?.profession ?? "Mangler info"}</p>
                )}
              </div>
              <div className="item-container">
                <label className="b3" htmlFor="adressLine1">
                  Adresse
                </label>
                {isEditMode ? (
                  <input
                    className="b1"
                    type="text"
                    id="addressLine1"
                    value={formValues.addressLine1}
                    onChange={handleChange}
                  />
                ) : (
                  <p>{formValues.addressLine1 ?? "Mangler info"}</p>
                )}
              </div>
              <div className="item-container">
                <label className="b3" htmlFor="postcode">
                  Postnummer
                </label>
                {isEditMode ? (
                  <input
                    className="b1"
                    type="text"
                    id="postcode"
                    value={formValues.postcode}
                    onChange={handleChange}
                  />
                ) : (
                  <p>{formValues.postcode ?? "Mangler info"}</p>
                )}
              </div>
              <div className="item-container">
                <label className="b3" htmlFor="city">
                  Sted
                </label>
                {isEditMode ? (
                  <input
                    className="b1"
                    type="text"
                    id="city"
                    value={formValues.city}
                    onChange={handleChange}
                  />
                ) : (
                  <p>{formValues.city ?? "Mangler info"}</p>
                )}
              </div>
              <div className="btn-container">
                {isEditMode ? (
                  <button
                    type="submit"
                    className="tertiary"
                    disabled={networking}
                  >
                    {networking ? "Lagrer…" : "Lagre"} {arrow}
                  </button>
                ) : (
                  <button
                    type="button"
                    className="tertiary"
                    onClick={(e) => {
                      setIsEditMode(true);
                      e.preventDefault();
                    }}
                  >
                    Rediger {arrow}
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </Busy>
  );
};

export default MyProfile;
